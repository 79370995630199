import { PageTemplate } from './PageTemplate'
import { CandidateJobsComponent } from '../components/Candidate/CandidateJobsComponent'
import { CandidateJobComponent } from '../components/Candidate/CandidateJobComponent'

const ShowCandidateJobsPage = () => {
    return (
        <PageTemplate
            type="candidate" headerPage="jobpage" headerElement="header"
            leftPaneComponent={CandidateJobsComponent()} mainComponent={CandidateJobComponent()} buttonComponent={null} >
        <></>
        </PageTemplate>
    );
}
export { ShowCandidateJobsPage }